import API from 'api'
import { GROUPS_OF_USERS_CAN_GIVE_DISCOUNTS_ABOVE_LIMIT } from 'constants/discount'
import { GroupEnum } from 'enums/groupEnum'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [displayGroup, setDisplayGroup] = useState(null)

  const getUserInfo = useCallback(async () => {
    if (user) {
      return
    }

    const userId = localStorage.getItem('userId')

    try {
      const response = await API.get(`/user/view/${userId}`)
      setUser(response.data[0])
      setDisplayGroup(response.data?.display_group)
    } catch (error) {
      console.log('error')
    }
  }, [user])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const userCanGiveDiscountAboveLimit = useMemo(
    () => GROUPS_OF_USERS_CAN_GIVE_DISCOUNTS_ABOVE_LIMIT.includes(displayGroup),
    [displayGroup],
  )

  const currentUserIsACloser = useMemo(
    () => displayGroup === GroupEnum.CLOSER,
    [displayGroup],
  )

  const values = useMemo(() => {
    return {
      user,
      setUser,
      setDisplayGroup,
      userCanGiveDiscountAboveLimit,
      currentUserIsACloser,
    }
  }, [user, userCanGiveDiscountAboveLimit, currentUserIsACloser])

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}
