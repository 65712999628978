export const USER_ROLE_ENUM = {
  superAdmin: 1,
}

export const USERS_EMAILS_WITH_PERMISSION = [
  'giovanni.nicola@77sol.com.br',
  'israel.lana@77sol.com.br',
  'bianca.barbosa@77sol.com.br',
  'biancamsb@hotmail.com',
  'gabriela.quass@77sol.com.br',
  'antonio.colombo@77sol.com.br',
  'caue.gimeno@77sol.com.br',
  'luca.milani@77sol.com.br',
  'thais.chehab@77sol.com.br',
  'kadini.katira@77sol.com.br',
  'brenda.sandes@77sol.com.br',
  "leticia.mendes@77sol.com.br",
  "valentina.felix@77sol.com.br",
]
