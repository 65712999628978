import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Divider,
  Paper,
  Button,
  Typography,
  Grid,
  TextField,
  TableHead,
  Tooltip,
  Chip,
  Switch,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  MenuItem,
  Tabs,
  Tab,
  Box,
  AppBar,
  Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import moment from 'moment'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import useRouter from 'utils/useRouter'
import { urlLanding, urlLogin } from 'urls'
import ReactInputMask from 'react-input-mask'
import { InfoRounded } from '@material-ui/icons'
import { useBlacklist } from 'hooks/useBlacklist'
import {
  formatCPF,
  formatCNPJ,
  detectFormat,
  formatCEP,
} from 'utils/dataFormat'
import API from 'api'
import { useAuth } from 'context/authContext'
import { useMessage } from 'hooks/useMessage'
import { USERS_EMAILS_WITH_PERMISSION } from 'constants/users'
import { SolLeads } from './components/Contents/SolLeads'
import { INITIAL, OPTIONS } from './constants'
import { useStyles } from './styles'
import { Form } from './components/Form'
import { UserTabs } from './components/Tabs'

function a11yPropsIntegrador(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const separateData = (data, values) => {
  const separate = {}
  Object.entries(data).forEach(([key, value]) => {
    if (values.hasOwnProperty(key)) {
      separate[key] = value || ''
    }
  })
  return separate
}

const searchCep = async (cep) => {
  const { data } = await API.get(`/showcep?cep=${cep}`)
  return {
    endereco: data.logradouro || '',
    bairro: data.bairro || '',
    cidade: data.cidade || '',
    uf: data.uf || '',
  }
}

const differentData = (
  different = false,
  text = 'Dado divergente do cadastro',
) => {
  return different
    ? {
        endAdornment: (
          <Tooltip title={text}>
            <Box color="orange">
              <InfoRounded fontSize="small" color="inherit" />
            </Box>
          </Tooltip>
        ),
      }
    : {}
}

const propsTextField = {
  fullWidth: true,
  variant: 'outlined',
  InputLabelProps: {
    shrink: true,
  },
}

function IntegratorModal({ integratorId, openModalIntegrator, onClose }) {
  const classes = useStyles()

  const { consultBlacklist, existInBlacklist, validBlacklist } = useBlacklist()

  const [userBlock, setUserBlock] = useState('Não')

  const [infoIntegrador, setInfoIntegrador] = useState([])
  const [valueIntegrador, setValueIntegrador] = useState('one')

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [messageSnackbar, setMessageSnackbar] = useState('')
  const [severity, setSeverity] = useState('')
  const [redirectLink, setRedirectLink] = useState()
  const [vendedoresArray, setVendedoresArray] = useState([])
  const [vendedor, setVendedor] = useState()

  const [permissionsUsers, setPermissionsUsers] = useState([])
  const [openModalAddPermission, setOpenModalAddPermission] = useState(false)

  const [userPermissionToggle, setUserPermissionToggle] = useState(false)
  const [newPermission, setNewPermission] = useState()

  const [refresh, setRefresh] = useState()
  const [updateRegistration, setUpdateRegistration] = useState(
    INITIAL.UPDATE_REGISTRATION,
  )
  const [sefazRegistration, setSefazRegistration] = useState(
    INITIAL.SEFAZ_REGISTRATION,
  )
  const [differentRegistration, setDifferentRegistration] = useState(
    INITIAL.DIFFERENT_REGISTRATION,
  )

  const { user } = useAuth()
  const isUserWithPermission = USERS_EMAILS_WITH_PERMISSION.includes(
    user?.email,
  )

  useEffect(() => {
    API.get('/infos/states').then(({ data }) => (OPTIONS.STATES = data))
  }, [])

  const handleUpdateRegistration = () => {
    API.patch(`/user/update/${integratorId}`, {
      ...updateRegistration,
      json_sintegra: sefazRegistration,
    })
      .then((res) => {
        setSeverity('success')
        setMessageSnackbar('Dados alterados com sucesso!')
        setOpenSnackbar(true)
        setRefresh(res)
      })
      .catch(() => {
        setSeverity('error')
        setMessageSnackbar('Erro ao alterar os dados!')
        setOpenSnackbar(true)
      })
  }

  const handleAction = (action) => {
    if (action === 'update') {
      return 'Atualizar'
    }
    if (action === 'create') {
      return 'Criar'
    }
    if (action === 'delete') {
      return 'Deletar'
    }
    if (action === 'show') {
      return 'Listagem'
    }
    if (action === 'index') {
      return 'Visualizar'
    }
  }

  const router = useRouter()

  const handleCreatePermission = () => {
    API.post('/permisions/create-permisions-user', {
      permission_id: newPermission,
      user_id: integratorId,
      active: userPermissionToggle,
    })
      .then(() => {
        getPermissionsUsers()
        setOpenModalAddPermission(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleUpdatePermissionUsers = (id, permissionId, userId, active) => {
    API.patch(`/permisions/update-permisions-user/${id}`, {
      permission_id: permissionId,
      user_id: userId,
      active,
    })
      .then(() => {
        getPermissionsUsers()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDeletePermissionUsers = (id) => {
    API.delete(`/permisions/delete-permisions-user/${id}`)
      .then(() => {
        getPermissionsUsers()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const getPermissionsUsers = () => {
    API.get(
      `/permisions/list-permisions-user` +
        `?name=${
          infoIntegrador.user
            ? infoIntegrador.user.nome
            : infoIntegrador.user.razaoSocial
        }`,
    )
      .then((res) => {
        setPermissionsUsers(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const showSite = (prefixo = '') => {
    if (prefixo) {
      return `https://${prefixo}.${urlLanding}`
    }
    return 'Não cadastrado'
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const getVendedores = () => {
    API.get('users-admin').then((response) => {
      setVendedoresArray(response.data.usuarios)

      API.get(`/gestao/info-integrador?id=${integratorId}`).then((res) => {
        setInfoIntegrador(res.data)
        setUserBlock(OPTIONS.BLOCK[res.data.user.block])
        setDifferentRegistration(
          res.data.user.diff_field_data || INITIAL.DIFFERENT_REGISTRATION,
        )
        setSefazRegistration(
          res.data.user.json_sintegra &&
            JSON.parse(res.data.user.json_sintegra),
        )
        setUpdateRegistration(separateData(res.data.user, updateRegistration))
        if (res.data.user.seller_id) {
          setVendedor(
            response.data.usuarios.find(
              (item) => item.id == res.data.user.seller_id,
            ),
          )
        } else {
          setVendedor()
        }
      })
    })
  }

  const getLinkRedirect = () => {
    API.get(`/gestao/acessar-user/${integratorId}`)
      .then((res) => {
        setRedirectLink(`${urlLogin}?token=${res.data.token}`)
      })
      .catch(() => {
        setSeverity('error')
        setMessageSnackbar('Não foi possível acessar conta')
        setOpenSnackbar(true)
      })
  }

  useEffect(() => {
    if (integratorId && openModalIntegrator) {
      getVendedores()
      getLinkRedirect()
    }
  }, [refresh, openModalIntegrator])

  const handleChangeIntegrador = (event, newValue) => {
    setValueIntegrador(newValue)
  }

  useEffect(() => {
    if (valueIntegrador === 'three') {
      getPermissionsUsers()
    }
  }, [valueIntegrador])

  const { onInfoMessage } = useMessage()
  const handleRedirectToUsersAccount = () => {
    if (isUserWithPermission) {
      window.open(redirectLink)
    } else {
      onInfoMessage(
        'Essa ação não está mais disponível. Consulte os dados do Integrador pelas funcionalidades do Gestão.',
      )
    }
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalIntegrator}
        onClose={() => {
          onClose(false)
          setInfoIntegrador([])
          setRedirectLink()
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalIntegrator}>
          <div className={classes.paper}>
            {infoIntegrador.user ? (
              <>
                <Grid container>
                  <Grid md={12}>
                    <div style={{ float: 'left' }}>
                      <Typography variant="h5">
                        <b>Nome:</b>
                        {infoIntegrador?.user?.nome}
                      </Typography>
                      <Typography variant="h5">
                        <b>Razão Social:</b> {infoIntegrador?.user?.razaoSocial}
                      </Typography>
                      <Typography variant="h5">
                        <b>E-mail:</b> {infoIntegrador?.user?.email}
                      </Typography>
                      <Typography variant="h5">
                        <b>Contato:</b> {infoIntegrador?.user?.telefone}{' '}
                        {infoIntegrador?.user?.celular &&
                          `/ ${infoIntegrador?.user?.celular}`}
                      </Typography>
                      <Typography style={{ display: 'flex' }} variant="h5">
                        <b>Site:&nbsp;</b>{' '}
                        {showSite(infoIntegrador.user.prefixo_site)}
                        {infoIntegrador?.user?.prefixo_site && (
                          <a
                            style={{ display: 'flex', alignItems: 'center' }}
                            target="__blank"
                            href={showSite(infoIntegrador.user.prefixo_site)}
                          >
                            <VisibilityIcon
                              style={{
                                color: '#707072',
                                cursor: 'pointer',
                                marginLeft: '5px',
                                fontSize: '20px',
                              }}
                            />
                          </a>
                        )}
                      </Typography>
                    </div>

                    <div style={{ float: 'right' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          width: '200px',
                          marginRight: '10px',
                          textTransform: 'none',
                          marginTop: '-4px',
                        }}
                        onClick={() => {
                          router.history.push(
                            `/plataforma/projetos/usuario/${integratorId}`,
                          )
                          onClose(false)
                        }}
                      >
                        Ver projetos
                      </Button>
                      <Button
                        onClick={handleRedirectToUsersAccount}
                        variant="contained"
                        color="primary"
                        style={{
                          width: '200px',
                          textTransform: 'none',
                          marginTop: '-4px',
                        }}
                      >
                        Acessar conta
                      </Button>
                      <Form
                        setVendedor={setVendedor}
                        vendedor={vendedor}
                        vendedoresArray={vendedoresArray}
                        infoIntegrador={infoIntegrador}
                        setUserBlock={setUserBlock}
                        userBlock={userBlock}
                      />
                    </div>
                  </Grid>
                </Grid>

                <br />

                <AppBar
                  position="static"
                  color="default"
                  style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                >
                  <Tabs
                    value={valueIntegrador}
                    onChange={handleChangeIntegrador}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      value="one"
                      label="Usuários"
                      {...a11yPropsIntegrador('one')}
                      style={{ textTransform: 'none' }}
                    />
                    <Tab
                      style={{ textTransform: 'none' }}
                      value="two"
                      label="Pagamentos"
                      {...a11yPropsIntegrador('two')}
                    />
                    <Tab
                      style={{ textTransform: 'none' }}
                      value="three"
                      label="Permissões"
                      {...a11yPropsIntegrador('three')}
                    />
                    <Tab
                      style={{ textTransform: 'none' }}
                      value="four"
                      label="Cadastro"
                      {...a11yPropsIntegrador('four')}
                    />
                    <Tab
                      style={{ textTransform: 'none' }}
                      value="five"
                      label="Dados SEFAZ"
                      {...a11yPropsIntegrador('five')}
                    />
                    <Tab
                      style={{ textTransform: 'none' }}
                      value="six"
                      label="Tags"
                      {...a11yPropsIntegrador('six')}
                    />
                    <Tab
                      style={{ textTransform: 'none' }}
                      value="seven"
                      label="SolLeads"
                      {...a11yPropsIntegrador('seven')}
                    />

                    <Tab
                      style={{ textTransform: 'none' }}
                      value="eight"
                      label="Logs"
                      {...a11yPropsIntegrador('eight')}
                    />
                  </Tabs>
                </AppBar>
                <Divider style={{ backgroundColor: '#e0e0e0' }} />
                <TabPanel value={valueIntegrador} index="one">
                  {infoIntegrador.cadastrados.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="custom pagination table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Nível</TableCell>
                            <TableCell>Propostas</TableCell>
                            <TableCell>Dimensionamentos</TableCell>
                            <TableCell>Cotações</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {infoIntegrador.cadastrados.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell>{row.nome}</TableCell>
                              <TableCell>{row.nome_perfil}</TableCell>
                              <TableCell>{row.total_propostas}</TableCell>
                              <TableCell>
                                {row.total_dimensionamentos}
                              </TableCell>
                              <TableCell>{row.total_cotacoes}</TableCell>
                              <TableCell>
                                {row.block === 0 ? 'Ativo' : 'Inativo'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant="h6">
                      Ainda não há usuários cadastrados
                    </Typography>
                  )}
                </TabPanel>
                <TabPanel value={valueIntegrador} index="two">
                  {infoIntegrador.pagamentos.length > 0 ? (
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="custom pagination table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Modalidade</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Data</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {infoIntegrador.pagamentos.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell>{row.modalidade}</TableCell>
                              <TableCell>
                                {parseFloat(row.valor || 0).toLocaleString(
                                  'pt-br',
                                  { style: 'currency', currency: 'BRL' },
                                )}
                              </TableCell>
                              <TableCell style={{ textTransform: 'none' }}>
                                {row.status}
                              </TableCell>
                              <TableCell>
                                {moment(row.created_at).format('DD/MM/YYYY')}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography variant="h6">
                      Ainda não há pagamentos registrados
                    </Typography>
                  )}
                </TabPanel>
                <TabPanel value={valueIntegrador} index="three">
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <Paper
                        onClick={() => setOpenModalAddPermission(true)}
                        style={{
                          border: `2px solid gray`,
                          padding: '12px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          cursor: 'pointer',
                        }}
                      >
                        <AddOutlinedIcon
                          style={{ marginRight: '8px', marginBottom: '4px' }}
                          width="24"
                          height="24"
                          color="gray"
                        />
                        <Typography size="small">
                          Adicionar nova exceção
                        </Typography>
                      </Paper>
                    </Grid>
                    {permissionsUsers.map((item) => (
                      <Grid item md={4} xs={12}>
                        <Paper
                          style={{
                            border: `2px solid ${
                              item.active == 0 ? 'red' : 'green'
                            }`,
                            padding: '12px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              marginBottom: '12px',
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Switch
                                checked={item.active != 0}
                                onChange={() => null}
                                onClick={() =>
                                  handleUpdatePermissionUsers(
                                    item.id,
                                    item.permission_id,
                                    item.user_id,
                                    item.active == 0 ? 1 : 0,
                                  )
                                }
                                size="small"
                              />
                              <Typography
                                size="medium"
                                colorWeight="600"
                                style={{ marginLeft: '12px' }}
                              >
                                <div style={{ fontStyle: 'italic' }}>
                                  {item.origin}
                                </div>
                              </Typography>
                            </div>
                            <DeleteOutlineOutlinedIcon
                              onClick={() =>
                                handleDeletePermissionUsers(item.id)
                              }
                              width="24"
                              height="24"
                              color="gray"
                              style={{ cursor: 'pointer' }}
                            />
                          </div>
                          <Typography size="small">
                            E-mail do usuário: {item.email}
                          </Typography>
                          <Typography size="small">
                            Ação: {handleAction(item.action)} (
                            {item.active == 0 ? 'Não' : 'Sim'})
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
                <TabPanel value={valueIntegrador} index="four">
                  <Box
                    marginY={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Typography variant="h4">Dados cadastrados</Typography>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!validBlacklist}
                      onClick={handleUpdateRegistration}
                      style={{
                        textTransform: 'inherit',
                      }}
                    >
                      Salvar alterações
                    </Button>
                  </Box>
                  <Box className={classes.colorInput} marginY={2}>
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography>Dados do responsável:</Typography>
                      </Grid>

                      <Grid item md={6}>
                        <TextField
                          {...propsTextField}
                          label="Nome completo"
                          value={updateRegistration?.nome}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              nome: target.value,
                            }))
                          }
                        />
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          disabled
                          {...propsTextField}
                          label="E-mail"
                          value={updateRegistration?.email}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <TextField
                          {...propsTextField}
                          label="CPF"
                          error={existInBlacklist('cpf')}
                          value={formatCPF(updateRegistration?.cpfResponsavel, {
                            mask: true,
                          })}
                          onBlur={({ target }) =>
                            consultBlacklist('cpf', formatCPF(target.value))
                          }
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              cpfResponsavel: formatCPF(target.value),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          {...propsTextField}
                          label="Telefone"
                          value={detectFormat(
                            updateRegistration?.telefone,
                            ['PHONE', 'CELL_PHONE'],
                            { mask: true },
                          )}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              telefone: detectFormat(target.value, [
                                'PHONE',
                                'CELL_PHONE',
                              ]),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          {...propsTextField}
                          label="Celular"
                          error={existInBlacklist('mobile')}
                          value={detectFormat(
                            updateRegistration?.celular,
                            ['PHONE', 'CELL_PHONE'],
                            { mask: true },
                          )}
                          onBlur={({ target }) =>
                            consultBlacklist(
                              'mobile',
                              detectFormat(target.value, [
                                'PHONE',
                                'CELL_PHONE',
                              ]),
                            )
                          }
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              celular: detectFormat(target.value, [
                                'PHONE',
                                'CELL_PHONE',
                              ]),
                            }))
                          }
                        />
                      </Grid>

                      <Grid item md={12}>
                        <Typography>Dados da empresa:</Typography>
                      </Grid>

                      <Grid item md={5}>
                        <TextField
                          {...propsTextField}
                          label="Razão social"
                          value={updateRegistration?.razaoSocial}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              razaoSocial: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('razaoSocial'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          {...propsTextField}
                          label="Nome fantasia"
                          value={updateRegistration?.nomeFantasia}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              nomeFantasia: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('nomeFantasia'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          {...propsTextField}
                          label="CNPJ"
                          disabled={!isUserWithPermission}
                          error={existInBlacklist('cnpj')}
                          value={formatCNPJ(updateRegistration?.cnpj, {
                            mask: true,
                          })}
                          onBlur={({ target }) =>
                            consultBlacklist('cnpj', formatCNPJ(target.value))
                          }
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              cnpj: formatCNPJ(target.value),
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('cnpj'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item md={3}>
                        <TextField
                          {...propsTextField}
                          label="CEP"
                          value={formatCEP(updateRegistration?.cep, {
                            mask: true,
                          })}
                          onChange={({ target }) => {
                            setUpdateRegistration((state) => ({
                              ...state,
                              cep: target.value,
                            }))
                          }}
                          onBlur={({ target }) => {
                            searchCep(target.value).then((result) =>
                              setUpdateRegistration((state) => ({
                                ...state,
                                ...result,
                              })),
                            )
                          }}
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('cep'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          {...propsTextField}
                          label="Endereço"
                          value={updateRegistration?.endereco}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              endereco: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('endereco'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <TextField
                          {...propsTextField}
                          label="Número"
                          value={updateRegistration?.numero}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              numero: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('numero'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextField
                          {...propsTextField}
                          label="Complemento"
                          value={updateRegistration?.complemento}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              complemento: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('complemento'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item md={5}>
                        <TextField
                          {...propsTextField}
                          label="Bairro"
                          value={updateRegistration?.bairro}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              bairro: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('bairro'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={5}>
                        <TextField
                          {...propsTextField}
                          label="Cidade"
                          value={updateRegistration?.cidade}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              cidade: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('cidade'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <TextField
                          {...propsTextField}
                          select
                          label="UF"
                          value={updateRegistration?.uf}
                          onChange={({ target }) =>
                            setUpdateRegistration((state) => ({
                              ...state,
                              uf: target.value,
                            }))
                          }
                          InputProps={{
                            ...differentData(
                              differentRegistration.includes('uf'),
                              'Dado divergente da SEFAZ',
                            ),
                          }}
                        >
                          {OPTIONS.STATES.map((item) => (
                            <MenuItem value={item.uf}>{item.uf}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                <TabPanel value={valueIntegrador} index="five">
                  <Box marginY={2}>
                    <Typography variant="h4">Dados da SEFAZ</Typography>
                    {sefazRegistration ? (
                      <Box className={classes.colorInput} marginY={2}>
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <Typography>Dados da empresa:</Typography>
                          </Grid>
                          <Grid item md={6}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Nome empresarial"
                              value={sefazRegistration.nome_empresarial}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes('razaoSocial'),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Nome fantasia"
                              value={sefazRegistration.nome_fantasia}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes(
                                    'nomeFantasia',
                                  ),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <ReactInputMask
                              disabled
                              maskChar={null}
                              mask="99.999.999/9999-99"
                              value={sefazRegistration.cnpj}
                            >
                              {(propsInput) => (
                                <TextField
                                  disabled
                                  label="CNPJ"
                                  {...propsTextField}
                                  {...propsInput}
                                  InputProps={{
                                    ...differentData(
                                      differentRegistration.includes('cnpj'),
                                    ),
                                  }}
                                />
                              )}
                            </ReactInputMask>
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Situação CNPJ"
                              value={sefazRegistration.situacao_cnpj}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Porte da empresa"
                              value={sefazRegistration.porte_empresa}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <ReactInputMask
                              disabled
                              maskChar={null}
                              mask="998.999.999.999"
                              value={sefazRegistration.inscricao_estadual}
                            >
                              {(propsInput) => (
                                <TextField
                                  disabled
                                  label="Inscrição estadual"
                                  {...propsTextField}
                                  {...propsInput}
                                />
                              )}
                            </ReactInputMask>
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Situação IE"
                              value={sefazRegistration.situacao_ie}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Tipo de inscrição"
                              value={sefazRegistration.tipo_inscricao}
                            />
                          </Grid>
                          <Grid item md={4}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Informação da IE como destinatário"
                              value={
                                sefazRegistration.informacao_ie_como_destinatario
                              }
                            />
                          </Grid>
                          <Grid item md={8}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Regime de tributação"
                              value={sefazRegistration.regime_tributacao}
                            />
                          </Grid>
                          <Grid item md={4}>
                            <ReactInputMask
                              disabled
                              maskChar={null}
                              mask="99/99/9999"
                              value={sefazRegistration.data_situacao_cadastral}
                            >
                              {(propsInput) => (
                                <TextField
                                  disabled
                                  label="Data situação cadastral"
                                  {...propsTextField}
                                  {...propsInput}
                                />
                              )}
                            </ReactInputMask>
                          </Grid>
                          <Grid item md={4}>
                            <ReactInputMask
                              disabled
                              maskChar={null}
                              mask="99/99/9999"
                              value={sefazRegistration.data_inicio_atividade}
                            >
                              {(propsInput) => (
                                <TextField
                                  disabled
                                  labe="Data início de atividade"
                                  {...propsTextField}
                                  {...propsInput}
                                />
                              )}
                            </ReactInputMask>
                          </Grid>
                          <Grid item md={4}>
                            <Typography />
                            <ReactInputMask
                              disabled
                              maskChar={null}
                              mask="99/99/9999"
                              value={sefazRegistration.data_fim_atividade}
                            >
                              {(propsInput) => (
                                <TextField
                                  disabled
                                  label="Data fim de atividade"
                                  {...propsTextField}
                                  {...propsInput}
                                />
                              )}
                            </ReactInputMask>
                          </Grid>
                          <Grid item md={12}>
                            <Typography>Dados de endereço:</Typography>
                          </Grid>
                          <Grid item md={8}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Logradouro"
                              value={sefazRegistration.logradouro}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes('endereco'),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={4}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Número"
                              value={sefazRegistration.numero}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes('numero'),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Complemento"
                              value={sefazRegistration.complemento}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes('complemento'),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={6}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Bairro"
                              value={sefazRegistration.bairro}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes('bairro'),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={5}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Município"
                              value={sefazRegistration.municipio}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes('cidade'),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={5}>
                            <ReactInputMask
                              disabled
                              maskChar={null}
                              mask="99999-999"
                              value={sefazRegistration.cep}
                            >
                              {(propsInput) => (
                                <TextField
                                  disabled
                                  label="CEP"
                                  {...propsTextField}
                                  {...propsInput}
                                  InputProps={{
                                    ...differentData(
                                      differentRegistration.includes('cep'),
                                    ),
                                  }}
                                />
                              )}
                            </ReactInputMask>
                          </Grid>
                          <Grid item md={2}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="UF"
                              value={sefazRegistration.uf}
                              InputProps={{
                                ...differentData(
                                  differentRegistration.includes('uf'),
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item md={12}>
                            <Typography>Dados da CNAE</Typography>
                          </Grid>
                          <Grid item md={2}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Código"
                              value={sefazRegistration.cnae_principal.code}
                            />
                          </Grid>
                          <Grid item md={10}>
                            <TextField
                              disabled
                              {...propsTextField}
                              label="Atividade"
                              value={sefazRegistration.cnae_principal.text}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    ) : (
                      <Box marginY={2} color="gray">
                        <Typography variant="h5" color="inherit">
                          Falha na consulta Sefaz / CNPJ não encontrado
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value={valueIntegrador} index="six">
                  <Grid container spacing={2}>
                    {infoIntegrador.tags.map((row) => (
                      <Grid item>
                        <Chip color="primary" label={row.name} size="small" />
                      </Grid>
                    ))}
                  </Grid>
                </TabPanel>
                <TabPanel value={valueIntegrador} index="seven">
                  <SolLeads integratorId={integratorId} />
                </TabPanel>
                <TabPanel value={valueIntegrador} index="eight">
                  <UserTabs.Logs userId={integratorId} />
                </TabPanel>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress style={{ color: '#707072' }} />
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          variant="filled"
          severity={severity}
        >
          {messageSnackbar}
        </Alert>
      </Snackbar>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalAddPermission}
        onClose={() => {
          setOpenModalAddPermission(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalAddPermission}>
          <div className={classes.paperSmall}>
            <center
              style={{
                color: '#707070',
                fontSize: '20px',
                marginTop: '16px',
                marginBottom: '24px',
              }}
            >
              Adicionar uma exceção
            </center>
            <TextField
              id="standard-select-currency"
              style={{ marginBottom: '12px', width: '100%' }}
              value={newPermission}
              onChange={(e) => {
                setNewPermission(e.target.value)
              }}
              label="ID da permissão"
              variant="outlined"
              size="small"
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
                marginTop: '12px',
              }}
            >
              <div
                style={{
                  marginTop: '4px',
                  fontSize: '14px',
                  marginRight: '12px',
                }}
              >
                Inativa
              </div>
              <Switch
                checked={userPermissionToggle}
                onChange={() =>
                  userPermissionToggle
                    ? setUserPermissionToggle(false)
                    : setUserPermissionToggle(true)
                }
                size="small"
              />
              <div
                style={{
                  marginTop: '4px',
                  fontSize: '14px',
                  marginLeft: '12px',
                }}
              >
                Ativa
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '12px',
              }}
            >
              <Button
                onClick={() => {
                  setOpenModalAddPermission(false)
                }}
                variant="outlined"
                color="red"
                style={{ textTransform: 'none', marginRight: '12px' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleCreatePermission}
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Adicionar
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

IntegratorModal.propTypes = {
  className: PropTypes.string,
}

export default IntegratorModal
